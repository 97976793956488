import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import React, { useRef, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import Footer from '../../components/footer/footer';
import BreadcrumbsBlock from '../../common/breadcrumbsBlock/breadcrumbsBlock';
import * as Styled from './searchPageTemplateStyle';
import SearchPage from './SearchPage';
import LocationsIcon from '../../images/icon-view-locations.gif';
import GlobalStyles from '../../styles/globalStyles';
import TopBanner from '../../common/topBanner/topBanner';
import { addTrailingSlash, isExternalUrlhref } from '../../utils';
import LogoOG from '../../images/logo-og.jpg';

const SearchPageTemplate = ({ data, location }) => {
  const params = new URLSearchParams(location.search.slice(1));
  const q = params.get('q') || '';
  const inputEl = useRef(null);
  const [query, setQuery] = React.useState(q);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    // `inputEl.current` points to the mounted search input element
    const q = inputEl.current.value;
    if(q){
      navigate(`/search/?q=${q}`);
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    setQuery(params.get('q') || '');
  }, [location]);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Parkland | Chevron :: Search</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta
          name="description"
          content="Parkland Fuel Corporation is the exclusive marketer of Chevron fuel in Canada."
        ></meta>
        <meta
          name="keywords"
          content="Parkland, Chevron, Fuel, Canada, Retail, Commercial, Gas Station, Cardlock"
        ></meta>
        <meta name="robots" content="noindex"></meta>
        <meta name="generator" content="concrete5 - 5.6.3.2"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Parkland | Chevron"></meta>
        <meta
          property="og:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta property="og:image" content={LogoOG}></meta>
        <meta
          property="og:url"
          content={addTrailingSlash(process.env.GATSBY_DOMAIN_URL)}
        ></meta>
        <meta name="twitter:title" content="Parkland | Chevron"></meta>
        <meta
          name="twitter:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <DefaultHeaderMenu />
      <BootstrapNavbar />
      <TopBanner path={location.pathname} />
      <BreadcrumbsBlock path={location.pathname} />

      <Styled.AboutContainer className="p-0">
        <Styled.LeftContainer xs={12} lg={4}></Styled.LeftContainer>
        <Styled.RightContainer xs={12} lg={8}>
          <Styled.SearchTitle id="search-title">Search</Styled.SearchTitle>
          <Row className="g-0">
            <Col xs md={7} lg={6}>
              <Styled.InputSearch
                ref={inputEl}
                name="query"
                type="text"
                value={query}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
              />
              <Styled.InputSubmit
                name="submit"
                type="submit"
                value="Search"
                onClick={handleSubmit}
              />
            </Col>
            <Styled.ColStyle xs={12} md={5} lg={6}>
              <Styled.ParaText>Looking for a location?</Styled.ParaText>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref('/retail/locations/')
                    ? '/retail/locations/'
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + '/retail/locations/'
                      )
                }
              >
                Retail Map
              </Styled.LinkStyle>
              <> | </>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref('/commercial-industrial/locations/')
                    ? '/commercial-industrial/locations/'
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL +
                          '/commercial-industrial/locations/'
                      )
                }
              >
                Commercial Map
              </Styled.LinkStyle>
              <Styled.LocationImage src={LocationsIcon} />
            </Styled.ColStyle>
          </Row>
          <SearchPage data={data} location={location} />
        </Styled.RightContainer>
      </Styled.AboutContainer>
      <div style={{ clear: 'both' }}></div>
      <Footer />
    </>
  );
};

export default SearchPageTemplate;

export const pageQuery = graphql`
  query SearchPageMyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
