import React from 'react';
import * as Styled from './searchPageTemplateStyle';
import Pagination from 'react-js-pagination';
import Highlighter from 'react-highlight-words';
import { addTrailingSlash } from '../../utils';
import { getSearchData } from '../../../api/searchApi';

const SearchPage = ({ location }) => {
  const params = new URLSearchParams(location?.search);
  const q = params.get('q') || '';

  const itemsPerPage = 5;
  const [activePage, setActivePage] = React.useState(0);
  const [results, setResults] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [currentElement, setcurrentElement] = React.useState([]);

  const search = () => {
    getSearchData(q)
      .then(({ data }) => {
        const visitedSlugs = new Set();

        const filteredHit = data?.hits?.hit?.filter((obj) => {
          if (visitedSlugs?.has(obj?.fields?.slug)) {
            return false;
          } else {
            visitedSlugs.add(obj?.fields?.slug);
            return true;
          }
        });
        setResults(filteredHit);
        setcurrentElement(filteredHit?.slice(activePage, itemsPerPage));
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });
  };

  React.useEffect(() => {
    if (q) {
      setLoading(true);
      search();
    }
  }, [location]);

  const getDataWithEllipsis = (content) => {
    const indexOfSearchInContent = content
      ?.toLowerCase()
      ?.indexOf(q?.toLowerCase());

    if (indexOfSearchInContent === -1) {
      return content?.substr(0, 250).concat(content?.length > 250 ? '...' : '');
    }
    if (content.length - (indexOfSearchInContent + q.length) < 250) {
      return '...' + content?.substr(-250);
    }
    if (content.length - (indexOfSearchInContent + q.length) > 250) {
      return indexOfSearchInContent > 3
        ? '...' + content?.substr(indexOfSearchInContent, 250) + '...'
        : content?.substr(indexOfSearchInContent, 250) + '...';
    }
    const data = content?.substr(
      Math.abs(indexOfSearchInContent),
      250 + indexOfSearchInContent
    );
    return data;
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setcurrentElement(paginate(results, itemsPerPage, pageNumber));
  };

  const paginate = (array, page_size, page_number) => {
    if (!array) {
      return [];
    }
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  };

  return (
    <>
      {isError ? (
        <Styled.NoResults>
          Something went wrong Please try again
        </Styled.NoResults>
      ) : q ? (
        currentElement && !isLoading ? (
          currentElement && currentElement?.length > 0 ? (
            currentElement?.map(({ fields }, index) => (
              <Styled.SearchResult key={index}>
                <a
                  href={
                    process.env.GATSBY_DOMAIN_URL +
                    addTrailingSlash(fields?.slug)
                  }
                >
                  <Styled.ResultAnchor>
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.title}
                    />
                  </Styled.ResultAnchor>
                </a>

                <Styled.ResultDesc>
                  {fields?.content_subtext
                    ?.toLowerCase()
                    ?.search(q?.toLowerCase()) >= 0 && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.content_subtext}
                    />
                  )}
                  {fields?.content_header
                    ?.toLowerCase()
                    ?.search(q?.toLowerCase()) >= 0 && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={fields?.content_header}
                    />
                  )}
                  {fields?.content_raw && (
                    <Highlighter
                      highlightClassName="highlightText"
                      searchWords={[q]}
                      autoEscape={true}
                      textToHighlight={getDataWithEllipsis(fields?.content_raw)}
                    />
                  )}
                </Styled.ResultDesc>

                <Styled.PageLink
                  href={
                    process.env.GATSBY_DOMAIN_URL +
                    addTrailingSlash(fields?.slug)
                  }
                >
                  <Highlighter
                    highlightClassName="highlightText"
                    searchWords={[q]}
                    autoEscape={true}
                    textToHighlight={
                      process.env.GATSBY_DOMAIN_URL +
                      addTrailingSlash(fields?.slug)
                    }
                  />
                </Styled.PageLink>
              </Styled.SearchResult>
            ))
          ) : (
            <Styled.NoResults>
              There were no results found. Please try another keyword or phrase.
            </Styled.NoResults>
          )
        ) : (
          <Styled.NoResults>Loading...</Styled.NoResults>
        )
      ) : (
        <Styled.NoResults>Please Enter a keyword to search</Styled.NoResults>

      )}

      {currentElement && currentElement.length && q ? (
        <Styled.PaginationDiv>
        <Pagination
          firstPageText=""
          lastPageText=""
          prevPageText="« Previous"
          nextPageText="Next »"
          activePage={activePage ? activePage : 1}
          itemsCountPerPage={5}
          totalItemsCount={results.length}
          pageRangeDisplayed={5}
          onChange={(e) => {
            handlePageChange(e);
            const scrollID = document?.getElementById('search-title');
              if (scrollID) {
                scrollID.scrollIntoView({ behavior: 'smooth' });
              }
          }}
          itemClass="itemClass"
          itemClassPrev="first"
          itemClassNext="last"
          disabledClass="disabled"
          linkClass="linkClass"
          activeLinkClass="activeLinkClass"
          linkClassFirst="linkClassFirst"
          linkClassLast="linkClassLast"
        />
      </Styled.PaginationDiv>
      ) : null}
    </>
  );
};

export default SearchPage;
