import styled from 'styled-components';
import { Col, Container } from 'react-bootstrap';

export const AboutContainer = styled(Container)`
  width: 1200px !important;
  max-width: 1200px !important;
  @media (max-width: 1200px) {
    max-width: 960px !important;
  }
  @media (max-width: 992px) {
    max-width: 728px !important;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }
  @media print {
    margin: 0 !important;
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;

export const LeftContainer = styled.div`
  float: left;
  width: 280px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  float: right;
  width: 920px;
  padding: 40px 0 55px 55px !important;

  @media (max-width: 1199px) {
    width: 700px;
  }
  @media (max-width: 991px) {
    float: none;
    width: 728px;
    padding: 25px 0 55px 0;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding: 25px !important;
  }
`;

export const SearchTitle = styled(Col)`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #083a81;
  font-size: 45px;
  line-height: 50px;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

export const InputSearch = styled.input`
  background: #d6ecd8;
  padding: 14px;
  border: none;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: #666;
  margin-right: 5px;
  width: 190px;
  font-weight: bold;
`;

export const ColStyle = styled(Col)`
  position: relative;
  margin: 0;
  padding: 12px 50px 0 0 !important;
  text-align: right;
  line-height: 18px;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.02em;
  @media (max-width: 1199px) {
    padding: 6px 50px 0 0 !important;
  }
  @media (max-width: 767px) {
    margin: 20px 0 0 0;
    padding: 12px 0 0 50px !important;
    text-align: left;
  }
`;
export const InputSubmit = styled.input`
  background: #33a23c;
  padding: 16px;
  border: none;
  font-family: 'Gotham-Medium', arial, sans-serif;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  color: #fff;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
`;

export const ParaText = styled.p`
  margin: 0;
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #1fb25a;
  display: inline-block;
  :hover {
    text-decoration: none;
    color: #0397d6;
  }
`;

export const BreakTag = styled.br`
  @media (min-width: 1285px) {
    display: none;
  }
`;

export const LocationImage = styled.img`
  position: absolute;
  width: 31px;
  height: auto;
  top: 0;
  right: 0;
  @media (max-width: 767px) {
    top: 5px;
    left: 0;
    width: 31px !important;
  }
`;

export const SearchResult = styled.div`
  margin-bottom: 16px;
  margin-top: 24px;
  border-bottom: 1px solid #e5e5e5;
`;

export const ResultAnchor = styled.a`
  text-decoration: none;
  color: #1fb25a;
  font-size: 26px;
  font-weight: 300 !important;
  font-family: 'Gotham-Light', arial, sans-serif;
  webkit-transition: color 0.15s ease-out;
  -moz-transition: color 0.15s ease-out;
  -o-transition: color 0.15s ease-out;
  -ms-transition: color 0.15s ease-out;
  transition: color 0.15s ease-out;
  :hover {
    color: #0397d6;
  }
`;

export const ResultDesc = styled.p`
  margin: 0 !important;
  padding: 0;
  background-repeat: no-repeat;
  background-position: top center;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const PageLink = styled.a`
  font-size: 12px;
  text-decoration: none !important;
  color: #999;
  display: block;
  font-size: 12px;
  margin: 2px 0px 8px;
  @media (max-width: 767px) {
    width: 100% !important;
    word-wrap: break-word !important;
  }
  :hover {
    color: #999;
  }
`;

export const NoResults = styled.div`
  margin: 0;
  padding: 0;
  font-weight: bold;
  background-position: top center;
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-top: 32px;
`;

export const PaginationDiv = styled.div`
  text-align: center;
  position: relative;
  ul {
    li {
      margin: 0 5px;
    }
    li:first-child {
      margin-left: 0;
    }
    li:last-child {
      margin-right: 0;
    }
  }
  .pagination {
    justify-content: center;
    display: flex;
    position: relative;
    gap: 2px;
    .first {
      position: absolute;
      top: 0;
      left: 0;
    }
    .last {
      position: absolute;
      top: 0;
      right: 0;
    }
    .activeLinkClass {
      pointer-events: none;
      color: #666;
      font-family: 'Gotham-Bold', arial, sans-serif;
    }
    .disabled{
      a{
        color: #666;
        pointer-events: none;
      }
    }
  }
`;

export const prev = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  margin-right: 4px;
  cursor: pointer;
`;

export const next = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 0px !important;
  cursor: pointer;
`;
